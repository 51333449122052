import { ListAlt } from "@mui/icons-material"

import AttributesList from "src/resources/attributes/AttributesList"
import AttributeCreate from "src/resources/attributes/AttributeCreate"
import AttributeShow from "src/resources/attributes/AttributeShow"
import AttributeEdit from "src/resources/attributes/AttributeEdit"
import Attribute from "../../models/Attribute"

const resource = {
  list: AttributesList,
  show: AttributeShow,
  create: AttributeCreate,
  edit: AttributeEdit,
  icon: ListAlt,
  recordRepresentation: (record: Attribute) => `${record.name}`,
}

export default resource
