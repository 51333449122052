import { Box, useMediaQuery, Theme } from "@mui/material"
import { ExportButton, FilterButton, FilterForm, FilterContext, ListBase, Pagination, SortButton, Title, TopToolbar, SelectInput, useListContext } from "react-admin"
import Aside from "src/resources/poi-media/Aside"
import ImageList from "src/resources/poi-media/ImageList"
import { PoiMediaType } from "src/models/PoiMedia"

const PoiMediaList = () => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"))

  return (
    <ListBase perPage={48} sort={{ field: "created_at", order: "DESC" }} filterDefaultValues={{}}>
      <Title defaultTitle={"Poi media"} />
      <FilterContext.Provider value={poiMediaFilters}>
        <ListActions isSmall={isSmall} />
        {isSmall && (
          <Box m={1}>
            <FilterForm />
          </Box>
        )}
      </FilterContext.Provider>
      <Box display="flex">
        <Aside />
        <Box width={isSmall ? "auto" : "calc(100% - 16em)"}>
          <ImageList selectable />
          <Pagination rowsPerPageOptions={[12, 24, 48, 72]} />
        </Box>
      </Box>
    </ListBase>
  )
}

export const poiMediaFilters = [
  <SelectInput alwaysOn source="media_type" label={"Media type"} name={"media_type"} choices={Object.keys(PoiMediaType).map((key) => ({ id: PoiMediaType[key], name: key }))} />,
]

const ListActions = ({ isSmall }: any) => (
  <TopToolbar sx={{ minHeight: { sm: 56 } }}>
    {isSmall && <FilterButton />}
    <SortButton fields={["created_at", "updated_at"]} />
    <ExportButton />
  </TopToolbar>
)

export default PoiMediaList
