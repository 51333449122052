import React, { useState } from "react"
import usePoiIDParam from "src/hooks/usePoiIDParam"
import { Create, ReferenceInput, required, SelectInput, SimpleForm } from "react-admin"
import Attribute from "src/models/Attribute"
import PoiAttributeValueInput from "src/resources/poi-attributes/PoiAttributeValueInput"
import AttributeSelectInput from "src/resources/attributes/AttributeSelectInput"

const PoiAttributeCreate = () => {
  const poi_id = usePoiIDParam()
  const redirect = poi_id ? `/pois/${poi_id}/show/attributes` : false
  const [selectedAttribute, setSelectedAttribute] = useState<Attribute>()

  const attributeSelected = (attribute: Attribute) => {
    setSelectedAttribute(attribute)
  }

  return (
    <Create redirect={redirect}>
      <SimpleForm defaultValues={{ poi_id }}>
        <ReferenceInput source="poi_id" reference="pois" allowEmpty validate={required()}>
          <SelectInput optionText="name" disabled fullWidth />
        </ReferenceInput>
        <ReferenceInput source="attribute_id" reference="attributes" allowEmpty validate={required()} perPage={9999} sort={{ field: "id", order: "ASC" }}>
          <AttributeSelectInput onAttributeSelected={attributeSelected} />
        </ReferenceInput>
        {selectedAttribute && <PoiAttributeValueInput attribute={selectedAttribute} />}
      </SimpleForm>
    </Create>
  )
}

export default PoiAttributeCreate
