import { useLocation } from "react-router"
import { parse } from "query-string"

const usePoiIDParam = () => {
  const { search } = useLocation()
  let poi_id = null
  const { poi_id: poi_id_string } = parse(search)
  if (typeof poi_id_string === "string") {
    poi_id = poi_id_string ? parseInt(poi_id_string, 10) : ""
  }

  return poi_id
}

export default usePoiIDParam
