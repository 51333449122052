import { List, Datagrid, TextField, DateField, NumberField } from "react-admin"

const AreaList = () => (
  <List perPage={25}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="type.name" label="Type" />
      <NumberField source="pois_count" label="Pois count" />
      <TextField source="is_searchable" />
      <NumberField
        source="extent"
        label={
          <div>
            <span>km</span>
            <sup>2</sup>
          </div>
        }
      />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
)

export default AreaList
