import { Admin, CustomRoutes, Resource } from "react-admin"
import { Layout } from "./Layout"
import { Route } from "react-router-dom"
import dataProvider from "src/data/dataProvider"
import authProvider from "src/authProvider"
import theme from "src/theme"

import { Comment } from "@mui/icons-material"

import { Dashboard } from "./dashboard/Dashboard"
import Pois from "src/resources/pois"
import PoiMedia from "src/resources/poi-media"
import PoiTypes from "src/resources/poi-types"
import Categories from "src/resources/categories"
import Attributes from "src/resources/attributes"
import PoiAttributes from "src/resources/poi-attributes"
import AreaTypes from "src/resources/area-types"
import Areas from "src/resources/areas"
import Users from "src/resources/users"
import Uploaders from "src/resources/uploaders"
import CommentList from "src/resources/comments/CommentList"
import PoiDescriptions from "src/resources/poi-descriptions"

function App() {
  return (
    <div className="App">
      <Admin theme={theme} dataProvider={dataProvider} authProvider={authProvider} title={"KZV admin"} layout={Layout}>
        <CustomRoutes>
          <Route path="/" element={<Dashboard />} />
        </CustomRoutes>
        <Resource name="pois" {...Pois} />
        <Resource name="poi-descriptions" {...PoiDescriptions} />
        <Resource name="poi-media" {...PoiMedia} />
        <Resource name="attributes" {...Attributes} />
        <Resource name="poi-attributes" {...PoiAttributes} />
        <Resource name="poi-types" {...PoiTypes} />
        <Resource name="areas" {...Areas} />
        <Resource name="area-types" {...AreaTypes} />
        <Resource name="users" {...Users} />
        <Resource name="uploaders" {...Uploaders} />
        <Resource name="comments" list={CommentList} icon={Comment} />
        <Resource name="categories" {...Categories} />
      </Admin>
    </div>
  )
}

export default App
