import { PermMedia } from "@mui/icons-material"

import PoiMediaList from "src/resources/poi-media/PoiMediaList"
import PoiMediaCreate from "src/resources/poi-media/PoiMediaCreate"
import PoiMediaEdit from "src/resources/poi-media/PoiMediaEdit"

const resource = {
  list: PoiMediaList,
  create: PoiMediaCreate,
  edit: PoiMediaEdit,
  icon: PermMedia,
}

export default resource
