import { apiBaseEndpoint } from "src/data/apiSettings"

const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(apiBaseEndpoint + "/public/login", {
      method: "POST",
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({ "Content-Type": "application/json", Accept: "application/json" }),
    })
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then((data) => {
        localStorage.setItem("auth", JSON.stringify({ ...data.user, token: data.access_token }))
      })
      .catch((error) => {
        throw new Error(error)
      })
  },
  checkError: (error) => {
    const status = error.status
    if (status === 401 || status === 403) {
      localStorage.removeItem("auth")
      return Promise.reject()
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve()
  },
  checkAuth: () => (localStorage.getItem("auth") ? Promise.resolve() : Promise.reject()),
  getPermissions: () => {
    // Required for the authentication to work
    return Promise.resolve()
  },
  getIdentity: () => {
    try {
      const { id, first_name, last_name } = JSON.parse(localStorage.getItem("auth"))
      return Promise.resolve({ id, fullName: first_name + " " + last_name })
    } catch (error) {
      return Promise.reject(error)
    }
  },
  logout: () => {
    return fetch(apiBaseEndpoint + "/logout", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        return response.json()
      })
      .then(() => {
        localStorage.removeItem("auth")
      })
      .catch(() => {
        throw new Error("Network error")
      })
  },
}

export default authProvider
