import React from "react"
import { Show, SimpleShowLayout, TextField, DateField, ArrayField, Datagrid, BooleanField, TopToolbar, EditButton, DeleteButton } from "react-admin"

const AttributeShowActions = () => (
  <TopToolbar>
    <EditButton />
    <DeleteButton />
  </TopToolbar>
)

const AttributeShow = () => {
  return (
    <Show actions={<AttributeShowActions />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="slug" />
        <TextField source="type" />
        <TextField source="helper_text" />
        <ArrayField source="options">
          <Datagrid bulkActionButtons={false}>
            <TextField source="value" />
            <TextField source="label" />
          </Datagrid>
        </ArrayField>
        <TextField source="unit" />
        <BooleanField source="multiple_choice" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </SimpleShowLayout>
    </Show>
  )
}

export default AttributeShow
