import React from "react"
import { useEditContext, Edit, EditBase, Form, TopToolbar, ListButton, TextInput, NumberInput, SaveButton, TextField, DeleteButton, ReferenceField, Labeled, BooleanInput } from "react-admin"
import PoiMediaImage from "src/resources/poi-media/PoiMediaImage"
import { Box, Card, CardHeader, Container, Grid, Stack } from "@mui/material"
import PoiMediaTypeInput from "src/resources/poi-media/PoiMediaTypeInput"
import PoiAutocompleteInput from "src/resources/pois/PoiAutocompleteInput"
import { ImageLabels } from "./ImageLabels"

const ConversionUrl = ({ label, source }: { label: string; source: string }) => {
  return <TextInput label={label} source={source} disabled helperText={false} variant={"outlined"} fullWidth />
}

const PoiMediaEdit = () => {
  return (
    <Container>
      <EditBase redirect={false}>
        <Card>
          <Form>
            <Box p={2}>
              <Grid container spacing={3} columns={{ xs: 1, md: 2 }}>
                <Grid item>
                  <PoiMediaImage />
                </Grid>
                <Grid item flexGrow={1}>
                  <Stack>
                    <ConversionUrl label={"Thumbnail"} source="files.conversions.thumb.url" />
                    <ConversionUrl label={"Medium"} source="files.conversions.medium.url" />
                    <ConversionUrl label={"Large"} source="files.conversions.large.url" />
                    <ConversionUrl label={"Original"} source="files.original.url" />
                  </Stack>
                  <DeleteButton />
                </Grid>
              </Grid>
              <Stack>
                <Grid container spacing={3}>
                  <Grid item>
                    <ReferenceField label="POI" source="poi_id" reference="pois" link="show">
                      <Labeled>
                        <TextField source="name" label={"POI"} />
                      </Labeled>
                    </ReferenceField>
                  </Grid>
                  <Grid item md={6}>
                    <ReferenceField label="Uploader" source="uploader_id" reference="uploaders" link="show">
                      <Labeled>
                        <TextField source="first_name" label={"Uploader"} />
                      </Labeled>
                    </ReferenceField>
                  </Grid>
                  <Grid item md={6}>
                    <ReferenceField label="Uploaded by" source="uploaded_by" reference="users" link="show">
                      <Labeled>
                        <TextField source="name" label={"User"} />
                      </Labeled>
                    </ReferenceField>
                  </Grid>
                </Grid>
                <PoiAutocompleteInput label={"Change POI"} />

                <PoiMediaTypeInput />
                <TextInput source="description" multiline resettable />
                <TextInput source="author" resettable />
                <NumberInput source="order" sx={{ maxWidth: 120 }} />
                <BooleanInput source="is_winter" />
              </Stack>
              <SaveButton />
            </Box>
          </Form>
        </Card>
        <Box mt={2}>
          <Card>
            <CardHeader title="Classified labels"></CardHeader>
            <ImageLabels />
          </Card>
        </Box>
      </EditBase>
    </Container>
  )
}

export default PoiMediaEdit
