import React, { useCallback } from "react"
import { Create, SimpleForm, TextInput, required, useCreate, useNotify, ReferenceArrayInput, AutocompleteArrayInput } from "react-admin"
import { useNavigate } from "react-router-dom"
import PoiTypeAutocompleteInput from "src/resources/poi-types/PoiTypeAutocompleteInput"

const PoiCreate = () => {
  const [create] = useCreate()
  const navigate = useNavigate()
  const notify = useNotify()

  const save = useCallback(
    async (values) => {
      if (!values.location.lat && !values.location.lng) delete values.location
      try {
        await create(
          "pois",
          { data: values },
          {
            onSuccess: (createdPoi) => {
              notify(`Poi created successfully`, { type: "success" })
              navigate(`/pois/${createdPoi.id}/show`)
            },
          }
        )
      } catch (error) {
        if (error.body?.errors) {
          // The shape of the returned validation errors must match the shape of the form
          let errors = {}
          for (let i = 0; i < error.body.errors.length; i++) {
            const singleError = error.body.errors[i]
            errors[singleError.source.pointer.replaceAll("/", ".")] = singleError.detail
          }

          return errors
        }
      }
    },
    [create, notify, navigate]
  )

  return (
    <Create>
      <SimpleForm onSubmit={save}>
        <TextInput source="name" validate={[required()]} fullWidth />
        <PoiTypeAutocompleteInput />

        <TextInput source="location.lat" label={"Latitude"} />
        <TextInput source="location.lng" label={"Longitude"} />

        <ReferenceArrayInput source="category_ids" reference="categories">
          <AutocompleteArrayInput label="Categories" fullWidth />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  )
}

export default PoiCreate
