import React from "react"
import { Create, SimpleForm, TextInput, required, ArrayInput, SimpleFormIterator, BooleanInput, FormDataConsumer } from "react-admin"
import AttributeTypeInput from "src/resources/attributes/AttributeTypeInput"

const AttributeCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <TextInput source="name" validate={[required()]} fullWidth />
        <AttributeTypeInput validate={[required()]} />
        <TextInput source="helper_text" fullWidth />
        <TextInput source="unit" />
        <FormDataConsumer>
          {({ formData }) => (
            <>
              <ArrayInput source="options">
                <SimpleFormIterator>
                  <TextInput source="value" validate={[required()]} />
                  <TextInput source="label" />
                </SimpleFormIterator>
              </ArrayInput>
              {formData.options?.length > 0 && <BooleanInput source="multiple_choice" />}
            </>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  )
}

export default AttributeCreate
