import * as React from "react"
import { Link } from "react-router-dom"
import { ButtonProps, Button } from "@mui/material"
import { LocationDescriptor } from "react-admin"

interface ButtonLinkProps extends ButtonProps {
  to: LocationDescriptor | string
}

const ButtonLink = ({ to, ...props }: ButtonLinkProps) => {
  // @ts-ignore: see https://github.com/mui-org/material-ui/issues/7877
  return <Button component={Link} to={to} {...props} />
}

export default ButtonLink
