import { List, Datagrid, TextField, DateField, NumberField, BooleanField } from "react-admin"

const CategoryList = () => (
  <List perPage={25}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <NumberField source="pois_count" label="Pois count" />
      <NumberField source="attributes_count" label="Key attributes" />
      <BooleanField source="is_published" label="Is published" />
      <BooleanField source="is_featured" label="Is featured" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
)

export default CategoryList
