import Attribute from "src/models/Attribute"

const mapAttribute = (model): Attribute => {
  return {
    id: model.id,
    name: model.name,
    slug: model.slug,
    type: model.type,
    unit: model.unit,
    options: model.options || [],
    helper_text: model.helper_text,
    multiple_choice: model.multiple_choice,
    created_at: model.created_at,
    updated_at: model.updated_at,
  }
}

export default mapAttribute
