import Poi from "src/models/Poi"
import ButtonLink from "src/common/ButtonLink"

const AddNewPoiDescriptionButton = ({ poi }: { poi: Poi }) => {
  return (
    <ButtonLink variant="contained" to={`/poi-descriptions/create?poi_id=${poi.id}`}>
      Add a description
    </ButtonLink>
  )
}

export default AddNewPoiDescriptionButton
