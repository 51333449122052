import PoiType from "src/models/PoiType"
import mapAttribute from "./mapAttribute"
import Attribute from "../Attribute"

const mapPoiType = (model): PoiType => {
  return {
    id: model.id,
    name: model.name,
    slug: model.slug,
    featured: model.featured,
    pois_count: model.pois_count,
    default_category: model.default_category,
    category_id: model.category_id,
    is_searchable: model.is_searchable,
    attributes_count: model.attributes_count,
    attributes: model.attributes ? model.attributes.map(mapAttribute) : null,
    attribute_ids: model.attributes ? model.attributes?.map((attr: Attribute) => attr.id) : null,
    created_at: model.created_at,
    updated_at: model.updated_at,
  }
}

export default mapPoiType
