import { Group } from "@mui/icons-material"

import UserList from "src/resources/users/UserList"
import UserCreate from "src/resources/users/UserCreate"
import UserEdit from "src/resources/users/UserEdit"

const resource = {
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  icon: Group,
}

export default resource
