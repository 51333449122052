import React from "react"
import { List, useRecordContext, TopToolbar, ExportButton } from "react-admin"
import { Box, Typography } from "@mui/material"
import ImageList from "src/resources/poi-media/ImageList"
import { poiMediaFilters } from "src/resources/poi-media/PoiMediaList"
import Poi from "src/models/Poi"
import ButtonLink from "src/common/ButtonLink"
import { FileUpload } from "@mui/icons-material"

const Empty = ({ poi }) => (
  <Box textAlign="center" m={1}>
    <Typography variant="h4" paragraph>
      Poi has no photos
    </Typography>
    <ButtonLink variant="contained" to={`/poi-media/create?poi_id=${poi.id}`}>
      Upload photos
    </ButtonLink>
  </Box>
)

const PoiPhotos = () => {
  const poi = useRecordContext<Poi>()
  return (
    <List
      perPage={25}
      sort={{ field: "order", order: "ASC" }}
      resource={"poi-media"}
      filter={{ poi_id: poi.id, poi_name: "", created_between: "", "poi.is_in_contest": "", "poi.publish_state": "", is_finalist: "", is_winter: "" }}
      filters={poiMediaFilters}
      empty={<Empty poi={poi} />}
      actions={
        <TopToolbar>
          <ButtonLink startIcon={<FileUpload />} variant="contained" to={`/poi-media/create?poi_id=${poi.id}`}>
            Upload photos
          </ButtonLink>
          <ExportButton />
        </TopToolbar>
      }
    >
      <Box display="flex" sx={{ overflow: "hidden" }}>
        <ImageList reordable={true} />
      </Box>
    </List>
  )
}

export default PoiPhotos
