import { CircleOutlined } from "@mui/icons-material"

import AreaTypeList from "src/resources/area-types/AreaTypeList"
import AreaTypeShow from "src/resources/area-types/AreaTypeShow"
import AreaTypeCreate from "src/resources/area-types/AreaTypeCreate"

const resource = {
  list: AreaTypeList,
  show: AreaTypeShow,
  create: AreaTypeCreate,
  icon: CircleOutlined,
}

export default resource
