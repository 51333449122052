import React, { useState } from "react"
import { Confirm, useDeleteMany, useListContext, useNotify, useRefresh, useUpdateMany } from "react-admin"
import { AppBar, Box, Button, IconButton, Toolbar } from "@mui/material"
import { X } from "react-feather"
import { CheckBox, Collections, DeleteForever } from "@mui/icons-material";

const BulkSelectToolbar = () => {
  const { onUnselectItems, selectedIds } = useListContext()
  const [open, setOpen] = useState(false)
  const notify = useNotify()
  const refresh = useRefresh()

  const [setManyGalleryPhoto] = useUpdateMany("poi-media", { ids: selectedIds, data: { media_type: "gallery-photo" } })
  const [setManyFinalists] = useUpdateMany("poi-media", { ids: selectedIds, data: { is_finalist: 1 } })
  const [setManyNotFinalists] = useUpdateMany("poi-media", { ids: selectedIds, data: { is_finalist: 0 } })
  const [deleteMany, { isLoading }] = useDeleteMany("poi-media", { ids: selectedIds })

  const handleAddMediaToGallery = () => {
    setManyGalleryPhoto()
    notify(`Poi media added to gallery`, { type: "success" })
    onUnselectItems()
    setTimeout(() => refresh(), 100)
  }
  const handlePhotosMarkFinalistClick = () => {
    setManyFinalists()
    notify(`Poi media marked as finalist`, { type: "success" })
    onUnselectItems()
    setTimeout(() => refresh(), 100)
  }

  const handlePhotosMarkNotFinalistClick = () => {
    setManyNotFinalists()
    notify(`Poi media marked as not finalists`, { type: "success" })
    onUnselectItems()
    setTimeout(() => refresh(), 100)
  }

  const handlePhotosRemoveClick = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)
  const handleConfirm = () => {
    deleteMany()
    setOpen(false)
    notify(`Poi media deleted`, { type: "success" })
    onUnselectItems()
  }

  return (
    <>
      <AppBar position={"sticky"} color={"default"}>
        <Toolbar variant="dense">
          <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={onUnselectItems}>
            <X />
          </IconButton>
          <Button variant={"contained"} startIcon={<Collections />} onClick={handleAddMediaToGallery}>
            Add to gallery
          </Button>
          <Box ml={2}>
            <Button variant={"contained"} startIcon={<CheckBox />} onClick={handlePhotosMarkFinalistClick}>
              Finalist
            </Button>
          </Box>
          <Box ml={2}>
            <Button variant={"contained"} startIcon={<CheckBox />} onClick={handlePhotosMarkNotFinalistClick}>
              Not a finalist
            </Button>
          </Box>
          <Box ml={2}>
            <Button variant={"text"} color={"error"} startIcon={<DeleteForever />} onClick={handlePhotosRemoveClick}>
              Delete
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Confirm
        isOpen={open}
        loading={isLoading}
        title={`Remove ${selectedIds.length} photos?`}
        content={`Are you sure you want to remove ${selectedIds.length} photos?`}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  )
}

export default BulkSelectToolbar
