import * as React from "react"
import { Card, CardContent } from "@mui/material"
import { Award, Aperture } from "react-feather"

import { FilterList, FilterListItem, FilterLiveSearch } from "react-admin"

const Aside = () => {
  // const { data } = useGetList<Category>("categories", {
  //   pagination: { page: 1, perPage: 100 },
  //   sort: { field: "name", order: "ASC" },
  // })

  return (
    <Card
      sx={{
        display: { xs: "none", md: "block" },
        order: -1,
        width: "15em",
        mr: 2,
        alignSelf: "flex-start",
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterLiveSearch source="poi_name" />

        <FilterList label="Media type" icon={<Aperture />}>
          <FilterListItem
            label="Gallery photos"
            value={{
              media_type: "gallery-photo",
              created_between: null,
              "poi.is_in_contest": null,
              "poi.publish_state": null,
              is_finalist: null,
              is_winter: null,
            }}
          />
          <FilterListItem
            label="Featured photos"
            value={{
              media_type: "featured-photo",
              created_between: null,
              "poi.is_in_contest": null,
              "poi.publish_state": null,
              is_finalist: null,
              is_winter: null,
            }}
          />
          <FilterListItem
            label="Uploaded photos"
            value={{
              media_type: "uploaded-photo",
              created_between: null,
              "poi.is_in_contest": null,
              "poi.publish_state": null,
              is_finalist: null,
              is_winter: null,
            }}
          />
          <FilterListItem
            label="Static map images"
            value={{
              media_type: "static-map-image",
              created_between: null,
              "poi.is_in_contest": null,
              "poi.publish_state": null,
              is_finalist: null,
              is_winter: null,
            }}
          />
          <FilterListItem
            label="Winter images"
            value={{
              is_winter: 1,
              created_between: null,
              "poi.is_in_contest": null,
              "poi.publish_state": null,
              is_finalist: null,
              media_type: null
            }}
          />
          <hr />
          <FilterListItem
            label="V nagradnem foto izboru 2023"
            value={{
              media_type: "gallery-photo,featured-photo",
              "poi.publish_state": "published,draft",
              "poi.is_in_contest": 1,
              created_between: ["2023-06-16", "2023-07-31"],
              is_finalist: null,
              is_winter: null,
            }}
          />
          <FilterListItem
            label="Finalist 2023"
            value={{
              media_type: null,
              is_finalist: 1,
              created_between: null,
              "poi.is_in_contest": null,
              "poi.publish_state": null,
              is_winter: null,
            }}
          />
        </FilterList>

      </CardContent>
    </Card>
  )
}

export default Aside
