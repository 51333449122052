import { List, Datagrid, TextField, DateField, ReferenceField } from "react-admin"

const CommentList = () => (
  <List perPage={100}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="comment" />
      <TextField source="user_email" />
      <TextField source="user_first_name" />
      <TextField source="user_last_name" />
      <ReferenceField source="poi_id" reference="pois" link="show">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
)

export default CommentList
