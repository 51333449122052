import React from "react"
import { Edit, required, SelectInput, SimpleForm, TextInput, TopToolbar, DeleteButton } from "react-admin"

const UserEditActions = () => (
  <TopToolbar>
    <DeleteButton />
  </TopToolbar>
)

const UserEdit = () => {
  return (
    <Edit actions={<UserEditActions />}>
      <SimpleForm>
        <TextInput source="name" validate={[required()]} fullWidth />
        <TextInput source="surname" validate={[required()]} fullWidth />
        <TextInput label="Email Address" source="email" type="email" validate={[required()]} />
        <TextInput source="password" label="New password" fullWidth />
        <TextInput source="role" value="admin" disabled />
        <SelectInput
          source="gender"
          validate={[required()]}
          choices={[
            { id: "female", name: "Female" },
            { id: "male", name: "Male" },
          ]}
        />
      </SimpleForm>
    </Edit>
  )
}

export default UserEdit
