import React from "react"
import { Create, SimpleForm, ReferenceInput, SelectInput, required, useGetOne, useGetList } from "react-admin"
import { RichTextInput } from "ra-input-rich-text"
import { Box, CardContent, Grid, Typography, Card } from "@mui/material"
import "./editorStyles.css"
import usePoiIDParam from "src/hooks/usePoiIDParam"
import ExternalDescription from "src/resources/pois/ExternalDescription"

const PoiDescriptionCreate = () => {
  const poi_id = usePoiIDParam()
  const redirect = poi_id ? `/pois/${poi_id}/show/description` : false

  const { data: poi } = useGetOne("pois", { id: poi_id })
  const { data: autoDescriptions } = useGetList("poi-descriptions", { filter: { poi_id } })

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Create redirect={redirect}>
            <SimpleForm defaultValues={{ poi_id }}>
              <ReferenceInput source="poi_id" reference="pois" allowEmpty validate={required()}>
                <SelectInput optionText="name" disabled />
              </ReferenceInput>
              <Box>
                <RichTextInput source="content" validate={required()} label={false} />
              </Box>
            </SimpleForm>
          </Create>
        </Grid>
        <Grid item md={6}>
          {autoDescriptions?.length > 0 && (
            <Box mt={2}>
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="h6">
                    Auto description
                  </Typography>
                  <Typography dangerouslySetInnerHTML={{ __html: autoDescriptions[0].content }} variant="body2" />
                </CardContent>
              </Card>
            </Box>
          )}
        </Grid>
      </Grid>
      <Box mt={2}> {poi && <>{poi.external_description && <ExternalDescription content={poi.external_description} url={poi.external_url} />}</>}</Box>
    </Box>
  )
}

export default PoiDescriptionCreate
