export enum AttributeType {
  BOOL = "boolean",
  STRING = "string",
  FLOAT = "float",
  INT = "integer",
  TEXT = "text",
  EMAIL = "email",
  URL = "url",
  ARRAY = "array",
}

export interface AttributeOption {
  value: string
  label: string
}

interface Attribute {
  id: number
  name: string
  slug: string
  type: AttributeType
  helper_text: string
  unit: string
  options: AttributeOption[]
  multiple_choice: boolean
  created_at: string
  updated_at: string
}

export default Attribute
