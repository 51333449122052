import React, { useEffect } from "react"
import Attribute from "src/models/Attribute"
import { SelectInput, useChoicesContext } from "react-admin"

const AttributeSelectInput = ({ onAttributeSelected, disabled = false }: { onAttributeSelected: (attribute: Attribute) => void; disabled?: boolean }) => {
  const { selectedChoices } = useChoicesContext()

  useEffect(() => {
    if (selectedChoices.length > 0) {
      onAttributeSelected(selectedChoices[0])
    } else {
      onAttributeSelected(null)
    }
  }, [selectedChoices, onAttributeSelected])

  return <SelectInput optionText="name" fullWidth disabled={disabled} />
}

export default AttributeSelectInput
