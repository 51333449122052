import {
  Datagrid,
  List,
  ReferenceInput,
  TextField,
  AutocompleteArrayInput,
  Pagination,
  SelectArrayInput,
  ReferenceArrayInput,
  ChipField,
  SingleFieldList,
  ArrayField,
  FunctionField,
  WrapperField,
  ExportButton,
  SortButton,
  TopToolbar,
  CreateButton,
  NumberField,
} from "react-admin"
import { Box } from "@mui/material"
import Poi from "src/models/Poi"
import PoiTypeIcon from "src/common/PoiTypeIcon"
import Aside from "src/resources/pois/Aside"
import { PublishStateField } from "./PublishStateField"

const PoiListPagination = (props) => <Pagination rowsPerPageOptions={[30, 60, 90, 120]} {...props} />

const PoiList = () => (
  <List aside={<Aside />} sort={{ field: "created_at", order: "DESC" }} filters={poiFilters} pagination={<PoiListPagination />} actions={<ListActions />} perPage={30}>
    <Datagrid optimized rowClick="show">
      <TextField source="name" />
      <WrapperField label="Poi type" sortable={false}>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <FunctionField render={(poi: Poi) => <PoiTypeIcon poiType={poi.type} />} />
          <TextField source="type.name" />
        </Box>
      </WrapperField>

      <WrapperField label="Publish state" sortable={false}>
        <PublishStateField size="small" />
      </WrapperField>

      <ArrayField source="categories" sortable={false}>
        <SingleFieldList linkType="show" resource="categories">
          <ChipField source="name" size="small" />
        </SingleFieldList>
      </ArrayField>
      <NumberField source="media_count" />
      <NumberField source="order_weight" />
      <TextField source="publish_state" />
    </Datagrid>
  </List>
)

const poiFilters = [
  <ReferenceInput label={"Poi type"} source="type_id" reference="poi-types">
    <SelectArrayInput optionText="name" optionValue="id" label={"Poi type"} />
  </ReferenceInput>,
  <ReferenceArrayInput label="Categories" source="category_id" reference="categories">
    <SelectArrayInput optionText="name" />
  </ReferenceArrayInput>,
  <ReferenceInput label="Areas" source="area_id" reference="areas">
    <AutocompleteArrayInput optionText="name" />
  </ReferenceInput>,
]

const ListActions = () => (
  <TopToolbar>
    <CreateButton label="Create new POI" />
    <SortButton fields={["created_at", "updated_at", "order_weight"]} />
    <ExportButton />
  </TopToolbar>
)

export default PoiList
