import React from "react"
import { Create, required, SimpleForm, TextInput, SelectInput, minLength, email } from "react-admin"

const UserCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm>
        <TextInput source="name" validate={[required()]} fullWidth />
        <TextInput source="surname" validate={[required()]} fullWidth />
        <TextInput label="Email Address" source="email" type="email" validate={[required(), email()]} />
        <TextInput source="password" validate={[required(), minLength(8)]} fullWidth />
        <SelectInput
          source="gender"
          validate={[required()]}
          choices={[
            { id: "female", name: "Female" },
            { id: "male", name: "Male" },
          ]}
        />
        <TextInput source="role" value="admin" defaultValue="admin" disabled />
      </SimpleForm>
    </Create>
  )
}

export default UserCreate
