import { FunctionField } from "react-admin"
import Poi from "src/models/Poi"

import { Chip } from "@mui/material"

type Size = "small" | "medium" | undefined

export const PublishStateField = ({ size = "medium", label = "Publish state" }: { size?: Size; label?: string }) => {
  return <FunctionField label={label} render={(poi: Poi) => <PublishStateIndicator size={size} poi={poi} />} />
}

const PublishStateIndicator = ({ poi, size }: { poi: Poi; size: Size }) => {
  switch (poi.publish_state) {
    case "draft":
      return <Chip color="default" label="Draft" size={size} />
    case "published":
      return <Chip color="success" label="Published" size={size} />
    case "pending":
      return <Chip color="info" label="Pending" size={size} />
    case "unpublished":
      return <Chip color="error" label="Unpublished" size={size} />
    default:
      return <Chip color="default" label={poi.publish_state} size={size} />
  }
}
