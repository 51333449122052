import * as React from "react"
import { Card, CardMedia, Box, Typography } from "@mui/material"
import { useRecordContext } from "react-admin"
import PoiMedia from "src/models/PoiMedia"
import Lightbox from "react-18-image-lightbox"
import { useState } from "react"

const PoiMediaImage = () => {
  const record = useRecordContext<PoiMedia>()
  const [lightbox, setLightbox] = useState(false)
  if (!record) return null
  return (
    <>
      <Card sx={{ display: "inline-block", position: "relative" }}>
        <CardMedia
          component="img"
          image={record.files.conversions?.thumb?.url || record.files?.original?.url}
          alt=""
          sx={{
            maxWidth: "42em",
            maxHeight: "15em",
            cursor: "pointer",
          }}
          onClick={() => setLightbox(true)}
        />
        <Box
          sx={{
            position: "absolute",
            background: "linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)",
            color: "white",
            zIndex: 2,
            bottom: 0,
            left: 0,
            width: "100%",
            padding: "5px 10px",
          }}
        >
          <Typography>
            {record.files?.original?.width}x{record.files?.original?.height}
          </Typography>
        </Box>
      </Card>

      {lightbox && <Lightbox mainSrc={record.files?.original?.url} onCloseRequest={() => setLightbox(false)} />}
    </>
  )
}

export default PoiMediaImage
