import { DataProvider } from "ra-core"
import { create, deleteMany, deleteResource, getList, getMany, getManyReference, getOne, reorderPoiMedia, update, updateMany, uploadPoiMedia } from "src/data/methods"

const dataProvider: DataProvider = {
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
  delete: deleteResource,
  deleteMany,
  updateMany,
  uploadPoiMedia,
  reorderPoiMedia,
}

export default dataProvider
