import Area from "src/models/Area"

const mapArea = (model): Area => {
  return {
    id: model.id,
    name: model.name,
    parent_id: model.parent_id,
    parent: model.parent ? mapArea(model.parent) : null,
    is_searchable: model.is_searchable,
    extent: model.extent,
    type_id: model.type_id,
    type: model.type,
    bounds_id: model.bounds_id,
    featured_image: model.featured_image,
    pois_count: model.pois_count,
    description: model.description,
    created_at: model.created_at,
    updated_at: model.updated_at,
  }
}

export default mapArea
