import React from "react"
import { SimpleForm, ReferenceInput, SelectInput, required, Edit } from "react-admin"
import { RichTextInput } from "ra-input-rich-text"
import { Box } from "@mui/material"
import { parse } from "query-string"
import { useLocation } from "react-router"
import "./editorStyles.css"

const PoiDescriptionEdit = () => {
  let poi_id
  const { search } = useLocation()
  const { poi_id: poi_id_string } = parse(search)
  if (typeof poi_id_string === "string") {
    poi_id = poi_id_string ? parseInt(poi_id_string, 10) : ""
  }

  const redirect = poi_id ? `/pois/${poi_id}/show/description` : false

  const transform = (data) => {
    delete data.type
    delete data.user_id
    return data
  }

  return (
    <Edit redirect={redirect} transform={transform}>
      <SimpleForm defaultValues={{ poi_id }}>
        <ReferenceInput source="poi_id" reference="pois" allowEmpty validate={required()}>
          <SelectInput optionText="name" disabled />
        </ReferenceInput>
        <Box>
          <RichTextInput source="content" validate={required()} label={false} />
        </Box>
      </SimpleForm>
    </Edit>
  )
}

export default PoiDescriptionEdit
