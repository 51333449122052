import { List, Datagrid, TextField, DateField, NumberField } from "react-admin"

const UploaderList = () => (
  <List perPage={25}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="email" />
      <NumberField source="pois_count" />
      <NumberField source="poi_media_count" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
)

export default UploaderList
