import PoiAttribute from "src/models/PoiAttribute"
import mapAttribute from "src/models/mapping/mapAttribute"
import mapPoi from "src/models/mapping/mapPoi"
import { AttributeType } from "../Attribute"

const mapPoiAttribute = (model): PoiAttribute => {
  return {
    id: model.id,
    poi_id: model.poi_id,
    attribute_id: model.attribute_id,
    attribute: model.attribute ? mapAttribute(model.attribute) : null,
    poi: model.poi ? mapPoi(model.poi) : null,
    value: model?.attribute ? model?.attribute.type === AttributeType.BOOL ? stringToBoolean(model.value.toString()) : model.value : model.value,
    created_at: model.created_at,
    updated_at: model.updated_at,
  }
}

const stringToBoolean = (stringValue: string) => {
  switch (stringValue?.toLowerCase()?.trim()) {
    case "true":
    case "yes":
    case "1":
      return true

    case "false":
    case "no":
    case "0":
    case null:
    case undefined:
      return false

    default:
      return JSON.parse(stringValue)
  }
}

export default mapPoiAttribute
