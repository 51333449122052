import React from "react"
import { ReferenceManyField, Datagrid, EditButton, TextField, useRecordContext, DateField } from "react-admin"
import ButtonLink from "src/common/ButtonLink"
import { Box } from "@mui/material"
import Poi from "src/models/Poi"
import { Plus } from "react-feather"

const PoiAttributes = () => {
  const poi = useRecordContext<Poi>()
  return (
    <div>
      <ReferenceManyField reference="poi-attributes" target="poi_id" label="Poi attributes" sort={{ field: "created_at", order: "DESC" }}>
        <Datagrid>
          <TextField source="attribute.name" sortable={false} />
          <TextField source="poi.name" sortable={false} />
          <TextField source="value" sortable={false} />
          <DateField source="created_at" />
          <DateField source="updated_at" />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
      <Box mt={2}>
        <ButtonLink startIcon={<Plus />} variant="contained" to={`/poi-attributes/create?poi_id=${poi.id}`}>
          Add an attribute
        </ButtonLink>
      </Box>
    </div>
  )
}

export default PoiAttributes
