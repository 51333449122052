import React from "react"
import { Show, SimpleShowLayout, TextField, DateField, NumberField, ReferenceManyField, Datagrid, Pagination, ReferenceField } from "react-admin"
import { Divider } from "@mui/material"

const AreaTypeShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="key" />
        <NumberField source="areas_count" />
        <Divider />
        <ReferenceManyField pagination={<Pagination />} perPage={10} reference="areas" target="type_id" label="Areas" sort={{ field: "created_at", order: "DESC" }}>
          <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="type.name" label="Area type" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  )
}

export default AreaTypeShow
