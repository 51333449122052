import { Box, ImageList as MuiImageList, ImageListItem, ImageListItemBar, Tooltip, IconButton, useTheme, useMediaQuery, Checkbox } from "@mui/material"
import { useCreatePath, useDataProvider, useListContext, useNotify, useRefresh } from "react-admin"
import { Link } from "react-router-dom"
import PoiMedia from "src/models/PoiMedia"
import { Star, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import BulkSelectToolbar from "src/resources/poi-media/BulkSelectToolbar"
import { useMemo, useState } from "react"
import { Gallery, Item } from 'react-photoswipe-gallery'

const ImageList = ({ reordable, selectable }: { reordable?: boolean; selectable?: boolean }) => {
  const { isLoading } = useListContext()
  return isLoading ? <LoadingGridList /> : <LoadedGridList reordable={reordable} selectable={selectable} />
}

const useColsForWidth = () => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up("sm"))
  const md = useMediaQuery(theme.breakpoints.up("md"))
  const lg = useMediaQuery(theme.breakpoints.up("lg"))
  const xl = useMediaQuery(theme.breakpoints.up("xl"))
  // there are all dividers of 24, to have full rows on each page
  if (xl) return 8
  if (lg) return 6
  if (md) return 4
  if (sm) return 3
  return 2
}

const times = (nbChildren: number, fn: (key: number) => any) => Array.from({ length: nbChildren }, (_, key) => fn(key))

const LoadingGridList = () => {
  const { perPage } = useListContext()
  const cols = useColsForWidth()
  return (
    <MuiImageList rowHeight={180} cols={cols} sx={{ m: 0 }}>
      {times(perPage, (key) => (
        <ImageListItem key={key} sx={{ overflow: "hidden", width: 120 }}>
          <Box bgcolor="grey.300" height="100%" />
        </ImageListItem>
      ))}
    </MuiImageList>
  )
}

const LoadedGridList = ({ reordable, selectable }: { reordable?: boolean; selectable?: boolean }) => {
  const { data }: { data: PoiMedia[] } = useListContext()
  const cols = useColsForWidth()
  const createPath = useCreatePath()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  const { selectedIds, onToggleItem } = useListContext()

  console.log(data);

  const handleSelected = (media: PoiMedia) => {
    onToggleItem(media.id)
  }

  const handleReorder = async (media: PoiMedia, direction: "up" | "down") => {
    try {
      await dataProvider.reorderPoiMedia({ media, direction })
      notify(`Order updated`, { type: "success" })
      refresh()
    } catch (e) {
      notify(`Something went wrong while updating order: ${e.message}`, { type: "error" })
    }
  }

  if (!data) return <LoadingGridList />

  return (
    <>
      {selectedIds.length > 0 && <BulkSelectToolbar />}
      <MuiImageList rowHeight={200} cols={cols} sx={{ m: 0 }} gap={10}>
        <Gallery>
        {data.map((media: PoiMedia, index) => (
          <ImageListItem key={media.id} sx={{ height: "100%" }}>
            <Box
              sx={{
                backgroundColor: "white",
                padding: "3px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: "1px solid #fefe",
              }}
            >
              {selectable && <Checkbox checked={selectedIds.includes(media.id)} onClick={() => handleSelected(media)} />}
              <span>
                {media.media_type === "featured-photo" && (
                  <Tooltip title="Featured photo">
                    <Star />
                  </Tooltip>
                )}
              </span>

              {reordable && (
                <Box sx={{}}>
                  <IconButton size="small" onClick={() => handleReorder(media, "up")}>
                    <Tooltip title="Order up">
                      <KeyboardArrowUp />
                    </Tooltip>
                  </IconButton>

                  <IconButton size="small" onClick={() => handleReorder(media, "down")}>
                    <Tooltip title="Order down">
                      <KeyboardArrowDown />
                    </Tooltip>
                  </IconButton>
                </Box>
              )}
            </Box>
            <Item original={media.files?.original?.url} thumbnail={media.files.conversions?.thumb?.url} width={media.files.original?.width} height={media.files.original?.height}>
              {({ ref, open }) => (
                <img
                  src={media.files.conversions?.thumb?.url || media.files?.original?.url}
                  alt=""
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null
                    currentTarget.src = media.files?.original?.url
                  }}
                  style={{ width: "100%", height: "100%", objectFit: "cover", cursor: "nwse-resize" }}
                  onClick={open}
                  ref={ref as React.MutableRefObject<HTMLImageElement>}
                />
              )}
            </Item>

            <Link
              style={{ position: "absolute", zIndex: 12, width: "100%", bottom: "0" }}
              to={createPath({
                resource: "poi-media",
                id: media.id,
                type: "edit",
              })}
            >
              <ImageListItemBar
                title={media.poi?.name}
                subtitle={
                  <>
                    {media.uploader?.name && <Box mb={1}>{media.uploader?.name}</Box>}
                    {media.user?.name && <Box mb={1}>{media.user?.name}</Box>}

                    <span>
                      {media.files?.original?.width}x{media.files?.original?.height}
                    </span>
                  </>
                }
                sx={{
                  background: "linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)",
                  "& .MuiImageListItemBar-titleWrap": {
                    padding: "8px",
                  },
                }}
              />
            </Link>
          </ImageListItem>
        ))}
        </Gallery>
      </MuiImageList>
    </>
  )
}

export default ImageList
