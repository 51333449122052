import { List, Datagrid, TextField, TextInput, DateField, Pagination } from "react-admin"

const filters = [<TextInput label="Search" source="name" size="small" alwaysOn name="name" />]

const AttributesPagination = (props) => <Pagination rowsPerPageOptions={[30, 60, 90, 120]} {...props} />

const AttributesList = () => (
  <List filters={filters} perPage={60} pagination={<AttributesPagination />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="type" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
)

export default AttributesList
