import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  NumberField,
  ReferenceManyField,
  Datagrid,
  Pagination,
  ImageField,
  RichTextField,
  BooleanField,
  Labeled,
  ChipField,
  SingleFieldList,
  ArrayField,
} from "react-admin"
import { Box, Divider, Stack } from "@mui/material"

const CategoryShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <Box sx={{ display: "flex", gap: 12 }}>
          <Stack spacing={2}>
            <Labeled>
              <TextField source="id" />
            </Labeled>
            <Labeled>
              <TextField source="name" />
            </Labeled>
            <Labeled>
              <TextField source="slug" />
            </Labeled>
            <Labeled>
              <BooleanField source="is_published" />
            </Labeled>
            <Labeled>
              <BooleanField source="is_featured" />
            </Labeled>
            <Labeled>
              <DateField source="created_at" />
            </Labeled>
            <Labeled>
              <DateField source="updated_at" />
            </Labeled>
          </Stack>
          <Stack spacing={2}>
            <Labeled>
              <ArrayField label="Key attributes" source="attributes">
                <SingleFieldList linkType="show" resource="attributes">
                  <ChipField source="name" size="small" />
                </SingleFieldList>
              </ArrayField>
            </Labeled>
          </Stack>
        </Box>

        <Divider />
        <ReferenceManyField pagination={<Pagination />} perPage={25} reference="pois" target="category_id" label="Pois" sort={{ field: "created_at", order: "DESC" }}>
          <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="publish_state" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  )
}

export default CategoryShow
