import React from "react"
import { AutocompleteInput, ReferenceInput, required } from "react-admin"
import Poi from "src/models/Poi"

const inputText = (choice) => `${choice.name}`

const matchSuggestion = (filter, choice: Poi) => {
  return choice.name.toLowerCase().includes(filter.toLowerCase())
}

const isOptionEqual = (option, value) => {
  return option.id == value.id
}

const PoiAutocompleteInput = ({ label = "POI" }: { label?: string }) => {
  return (
    <ReferenceInput source="poi_id" reference="pois">
      <AutocompleteInput
        inputText={inputText}
        optionText="name"
        optionValue="id"
        validate={[required()]}
        suggestionLimit={10}
        filterToQuery={(searchText) => ({ name: searchText })}
        matchSuggestion={matchSuggestion}
        isOptionEqualToValue={isOptionEqual}
        label={label}
      />
    </ReferenceInput>
  )
}

export default PoiAutocompleteInput
