import PoiType from "src/models/PoiType"
import Category from "./Category"

export interface LocationObject {
  lat: number
  lng: number
}

export enum PoiPublishState {
  pending = "pending",
  draft = "draft",
  published = "published",
  unpublished = "unpublished",
}

interface Poi {
  id: number
  name: string
  slug: string
  location: LocationObject
  type_id: number
  type: PoiType
  media_count?: number
  order_weight?: number
  municipality_id: number
  publish_state: PoiPublishState
  external_url: string
  external_description: string
  categories: Category[]
  category_ids?: number[]
  created_at: string
  updated_at: string
}

export default Poi
