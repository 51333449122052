import { apiBaseEndpoint } from "src/data/apiSettings"
import fetcher from "src/data/fetcher"
import resourceMap from "src/models/mapping"
import ApiAction from "src/data/actions"
import { CreateParams, DeleteManyParams, DeleteParams, GetListParams, GetManyParams, GetManyReferenceParams, GetOneParams, UpdateManyParams, UpdateParams } from "ra-core"
import PoiMedia, { PoiMediaType } from "src/models/PoiMedia"

const defaultApiSettings = {
  method: "GET",
  body: null,
  headers: new Headers({
    Accept: "application/json",
  }),
}

type GetQueryParams = GetListParams | GetManyReferenceParams

const getQueryParams = (params: GetQueryParams): URLSearchParams => {
  const { page, perPage } = params.pagination

  const queryParams = new URLSearchParams({
    "page[number]": page.toString(),
    "page[size]": perPage.toString(),
  })

  Object.keys(params.filter || {}).forEach((key) => {
    queryParams.append(`filter[${key}]`, params.filter[key])
  })

  if (params.sort && params.sort.field) {
    const prefix = params.sort.order === "ASC" ? "" : "-"
    queryParams.append("sort", `${prefix}${params.sort.field}`)
  }

  return queryParams
}

const fetchApi = (actionType: ApiAction, resource: string, url: string, options = {}) => {
  return fetcher(url, { ...defaultApiSettings, ...options }).then((response) => {
    let total = response.json?.meta?.total || response.json?.data?.length
    let data = response.json?.data

    if (!data) return { data: [] }

    if (Array.isArray(data)) {
      // Return mapped models if mapping is available
      if (typeof resourceMap[resource] === "function") {
        data = data.map((model) => resourceMap[resource](model))
      }
      return {
        data,
        total,
      }
    } else {
      // Return mapped model if mapping is available
      if (typeof resourceMap[resource] === "function") {
        data = resourceMap[resource](data)
      }
      return {
        data,
      }
    }
  })
}

export const getList = (resource, params: GetListParams) => {
  const queryParams = getQueryParams(params)
  const url = `${apiBaseEndpoint}/${resource}?${queryParams.toString()}`

  return fetchApi(ApiAction.GET_LIST, resource, url)
}

export const getOne = (resource, params: GetOneParams) => {
  const url = `${apiBaseEndpoint}/${resource}/${params.id}`
  return fetchApi(ApiAction.GET_ONE, resource, url)
}

export const getMany = (resource, params: GetManyParams) => {
  const queryParams = new URLSearchParams({ "filter[id]": params.ids.join(",") })
  const url = `${apiBaseEndpoint}/${resource}?${queryParams.toString()}`
  return fetchApi(ApiAction.GET_MANY, resource, url)
}

export const getManyReference = (resource, params: GetManyReferenceParams) => {
  const queryParams = getQueryParams(params)
  queryParams.append(`filter[${params.target}]`, params.id.toString())
  const url = `${apiBaseEndpoint}/${resource}?${queryParams.toString()}`
  return fetchApi(ApiAction.GET_MANY_REFERENCE, resource, url)
}

export const create = (resource, params: CreateParams) => {
  const url = `${apiBaseEndpoint}/${resource}`

  const options = {
    method: "POST",
    body: JSON.stringify(params.data),
  }
  return fetchApi(ApiAction.CREATE, resource, url, options)
}

export const update = async (resource, params: UpdateParams) => {
  const url = `${apiBaseEndpoint}/${resource}/${params.id}`
  const attributes = params.data
  delete attributes.id

  const requestBody = attributes

  if (params.data.image) {
    const base64image = await convertFileToBase64(params.data.image)
    requestBody.image = base64image
  }

  const options = {
    method: "PATCH",
    body: JSON.stringify(requestBody),
  }

  return fetchApi(ApiAction.UPDATE, resource, url, options)
}

export const deleteResource = (resource, params: DeleteParams) => {
  const url = `${apiBaseEndpoint}/${resource}/${params.id}`
  return fetchApi(ApiAction.DELETE, resource, url, { method: "DELETE" })
}

export const deleteMany = (resource, params: DeleteManyParams) => {
  const { ids } = params

  let deletePromises = []
  ids.forEach((id) => {
    const url = `${apiBaseEndpoint}/${resource}/${id}`
    deletePromises.push(fetchApi(ApiAction.DELETE, resource, url, { method: "DELETE" }))
  })
  return Promise.all(deletePromises).then((results) => ({ data: results }))
}

export const updateMany = (resource, params: UpdateManyParams) => {
  const { ids } = params
  const attributes = params.data
  delete attributes.id

  const options = {
    method: "PATCH",
    body: JSON.stringify(attributes),
  }

  let updatePromises = []
  ids.forEach((id) => {
    const url = `${apiBaseEndpoint}/${resource}/${id}`
    updatePromises.push(fetchApi(ApiAction.UPDATE, resource, url, options))
  })
  return Promise.all(updatePromises).then((results) => ({ data: results }))
}

export const uploadPoiMedia = ({ poi_id, media_type = PoiMediaType.GalleryPhoto, files }) => {
  const url = `${apiBaseEndpoint}/poi-media`
  let formData = new FormData()
  formData.set("poi_id", poi_id)
  formData.set("media_type", media_type)

  let uploadPromises = []
  for (let i = 0; i < files.length; i++) {
    const file = files[i]
    formData.set("file", file.rawFile)
    const uploadPromise = fetchApi(ApiAction.CREATE, "poi-media", url, { body: formData, method: "POST" })
    uploadPromises.push(uploadPromise)
  }

  return Promise.all(uploadPromises).then((results) => ({ data: results }))
}

export const reorderPoiMedia = ({ media, direction }: { media: PoiMedia; direction: "up" | "down" }) => {
  const url = `${apiBaseEndpoint}/poi-media/${media.id}/reorder`
  return fetchApi(ApiAction.REORDER_MEDIA, "poi-media", url, { method: "POST", body: JSON.stringify({ direction }) })
}

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject

    reader.readAsDataURL(file.rawFile)
  })
