import { Category } from "@mui/icons-material"

import PoiTypeList from "src/resources/poi-types/PoiTypeList"
import PoiTypeCreate from "src/resources/poi-types/PoiTypeCreate"
import PoiTypeShow from "src/resources/poi-types/PoiTypeShow"
import PoiTypeEdit from "src/resources/poi-types/PoiTypeEdit"

const resource = {
  show: PoiTypeShow,
  list: PoiTypeList,
  create: PoiTypeCreate,
  edit: PoiTypeEdit,
  icon: Category,
}

export default resource
