import { Edit, SimpleForm, TextInput, required, BooleanInput, AutocompleteInput, ReferenceInput, ReferenceArrayInput, AutocompleteArrayInput, useEditContext } from "react-admin"

const PoiTypeEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput source="name" validate={[required()]} fullWidth />
        <ReferenceInput label="Default category" source="category_id" reference="categories">
          <AutocompleteInput fullWidth />
        </ReferenceInput>
        <BooleanInput source="featured" />
        <ReferenceArrayInput source="attribute_ids" reference="attributes">
          <AutocompleteArrayInput label="Attributes" fullWidth />
        </ReferenceArrayInput>
        <BooleanInput name="is_searchable" source="is_searchable"/>
      </SimpleForm>
    </Edit>
  )
}

export default PoiTypeEdit
