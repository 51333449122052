import React from "react"
import { Create, required, SimpleForm, TextInput, SelectInput, minLength, email } from "react-admin"

const AreaTypeCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <TextInput source="name" validate={[required()]} fullWidth />
      </SimpleForm>
    </Create>
  )
}

export default AreaTypeCreate
