import React from "react"
import { Create, SimpleForm, TextInput, required, BooleanInput, ReferenceArrayInput, AutocompleteArrayInput, AutocompleteInput, ReferenceInput } from "react-admin"

const PoiTypeCreate = () => {
  return (
    <Create redirect="show">
      <SimpleForm>
        <TextInput source="name" validate={[required()]} fullWidth />
        <ReferenceInput source="category_id" reference="categories">
          <AutocompleteInput label="Default category" fullWidth />
        </ReferenceInput>
        <BooleanInput source="featured" />
        <ReferenceArrayInput source="attribute_ids" reference="attributes">
          <AutocompleteArrayInput label="Assigned attributes" fullWidth />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  )
}

export default PoiTypeCreate
