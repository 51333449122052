import { defaultTheme } from "react-admin"
import { colors } from "@mui/material"

const theme = {
  ...defaultTheme,
  palette: {
    type: "light",
    action: {
      active: colors.blueGrey[600],
    },
    background: {
      default: colors.common.white,
      dark: "#f4f6f8",
      paper: colors.common.white,
    },
    primary: {
      main: "#FBA919",
    },
    secondary: {
      main: "#FBA919",
    },
    text: {
      primary: "#414142",
      secondary: "#929497",
    },
  },
  typography: {
    h1: {
      fontWeight: 500,
      fontSize: "3rem",
      letterSpacing: "-0.24px",
    },
    h2: {
      fontWeight: 500,
      fontSize: "2rem",
      letterSpacing: "-0.24px",
    },
    h3: {
      fontWeight: 500,
      fontSize: "1.8rem",
      letterSpacing: "-0.06px",
    },
    h4: {
      fontWeight: 500,
      fontSize: "1.5rem",
      letterSpacing: "-0.06px",
    },
    h5: {
      fontWeight: 500,
      fontSize: "1.2rem",
      letterSpacing: "-0.05px",
    },
    h6: {
      fontWeight: 500,
      fontSize: "1.15rem",
      letterSpacing: "-0.05px",
    },
    overline: {
      fontWeight: 500,
    },
  },
  components: {
    ...defaultTheme.components,
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          // invisible border when not active, to avoid position flashs
          // borderLeft: "5px solid transparent",
          borderTopRightRadius: 16,
          borderBottomRightRadius: 16,
          "&.RaMenuItemLink-active": {
            // borderLeft: "5px solid #FBA919",
            background: "#FBA919",
            color: "black",
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: "12px",
        },
      },
    },
  },
}

export default theme
