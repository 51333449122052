import React from "react"
import { Show, SimpleShowLayout, TextField, DateField, NumberField, ReferenceManyField, Datagrid, Pagination, ImageField, RichTextField } from "react-admin"
import { Divider } from "@mui/material"

const AreaShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="type.name" />
        <TextField source="is_searchable" />
        <NumberField
          source="extent"
          label={
            <div>
              <span>km</span>
              <sup>2</sup>
            </div>
          }
        />
        <ImageField source="featured_image.conversions.thumb.url" label="Featured image" />
        <RichTextField source="description" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
        <Divider />
        <ReferenceManyField pagination={<Pagination />} perPage={25} reference="pois" target="area_id" label="Pois" sort={{ field: "created_at", order: "DESC" }}>
          <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="publish_state" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  )
}

export default AreaShow
