import Poi, { PoiPublishState } from "src/models/Poi"
import mapPoiType from "src/models/mapping/mapPoiType"

const mapPoiPublishState = (publish_state): PoiPublishState => {
  if (!PoiPublishState[publish_state]) {
    throw new Error(`Publish state ${publish_state} is not defined.`)
  } else {
    return PoiPublishState[publish_state]
  }
}

const mapPoi = (model): Poi => {
  return {
    id: model?.id,
    name: model.name,
    slug: model.slug,
    location: model.location,
    type_id: model.type_id,
    type: model.type ? mapPoiType(model.type) : null,
    municipality_id: model.municipality_id,
    publish_state: mapPoiPublishState(model.publish_state),
    external_url: model.external_url,
    external_description: model.external_description,
    media_count: model.media_count,
    order_weight: model.order_weight,
    categories: model.categories,
    category_ids: model.categories?.map((cat) => cat.id),
    created_at: model.created_at,
    updated_at: model.updated_at,
  }
}

export default mapPoi
