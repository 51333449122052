import React, { useState } from "react"
import { ReferenceInput, required, SelectInput, SimpleForm, Edit } from "react-admin"
import AttributeSelectInput from "src/resources/attributes/AttributeSelectInput"
import PoiAttributeValueInput from "src/resources/poi-attributes/PoiAttributeValueInput"
import Attribute from "src/models/Attribute"

const PoiAttributeEdit = () => {
  const [selectedAttribute, setSelectedAttribute] = useState<Attribute>()

  const attributeSelected = (attribute: Attribute) => {
    setSelectedAttribute(attribute)
  }

  return (
    <Edit
      redirect={(resource, id, data) => {
        return "pois/" + data.poi_id + "/show/attributes"
      }}
    >
      <SimpleForm>
        <ReferenceInput source="poi_id" reference="pois" allowEmpty validate={required()}>
          <SelectInput optionText="name" disabled />
        </ReferenceInput>
        <ReferenceInput source="attribute_id" reference="attributes" allowEmpty validate={required()}>
          <AttributeSelectInput onAttributeSelected={attributeSelected} disabled />
        </ReferenceInput>
        {selectedAttribute && <PoiAttributeValueInput attribute={selectedAttribute} />}
      </SimpleForm>
    </Edit>
  )
}

export default PoiAttributeEdit
