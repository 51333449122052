import { Box } from "@mui/material"
import { List, Datagrid, FunctionField, TextField, DateField, NumberField, BooleanField, ChipField, SingleFieldList, ArrayField, WrapperField } from "react-admin"
import PoiTypeIcon from "src/common/PoiTypeIcon"
import PoiType from "src/models/PoiType"

const PoiTypeList = () => (
  <List perPage={25}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <WrapperField label="Name" sortBy="name">
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <FunctionField render={(poiType: PoiType) => <PoiTypeIcon poiType={poiType} />} />
          <TextField source="name" />
        </Box>
      </WrapperField>
      <TextField source="slug" />
      <BooleanField source="is_searchable" label="Searchable?" />
      <BooleanField source="featured" label="Featured?"/>
      <NumberField source="pois_count" />
      <NumberField label="Assigned attributes" source="attributes_count" />
      <ChipField source="default_category.name" label="Default category" size="small" />
    </Datagrid>
  </List>
)

export default PoiTypeList
