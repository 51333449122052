import PoiMedia from "src/models/PoiMedia"
import mapPoi from "src/models/mapping/mapPoi"

const mapPoiMedia = (model): PoiMedia => {
  return {
    id: model.id,
    created_at: model.created_at,
    files: model.files,
    media_type: model.media_type,
    meta: model.meta,
    description: model.description,
    author: model.author,
    order: model.order,
    poi_id: model.poi_id,
    poi: model.poi ? mapPoi(model.poi) : null,
    uploader_id: model.uploader_id,
    uploader: model.uploader,
    uploaded_by: model.uploaded_by,
    updated_at: model.updated_at,
    is_winter: model.is_winter,
    user: model.user,
    classifiedImage: model.image,
  }
}

export default mapPoiMedia
