import PoiType from "src/models/PoiType"
import mapAttribute from "./mapAttribute"
import Attribute from "../Attribute"
import Category from "../Category"

const mapCategory = (model): Category => {
  return {
    id: model.id,
    name: model.name,
    slug: model.slug,
    is_featured: model.is_featured,
    is_published: model.is_published,
    pois_count: model.pois_count,
    attributes_count: model.attributes_count,
    attributes: model.attributes ? model.attributes.map(mapAttribute) : null,
    attribute_ids: model.attributes ? model.attributes?.map((attr: Attribute) => attr.id) : null,
    created_at: model.created_at,
    updated_at: model.updated_at,
  }
}

export default mapCategory
