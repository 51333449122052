import { Card, CardContent } from "@mui/material"
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges"
import { FilterList, FilterListItem, FilterLiveSearch } from "react-admin"

const publishStateFilters = [
  { value: "pending", label: "Pending" },
  { value: "draft", label: "Draft" },
  { value: "published", label: "Published" },
]

const Aside = () => {
  return (
    <Card
      sx={{
        display: { xs: "none", md: "block" },
        order: -1,
        flex: "0 0 15em",
        mr: 2,
        mt: 8,
        alignSelf: "flex-start",
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterLiveSearch source="name" />

        <FilterList label="Publish state" icon={<PublishedWithChangesIcon />}>
          {publishStateFilters.map((f) => (
            <FilterListItem
              key={f.value}
              label={f.label}
              value={{
                publish_state: f.value,
              }}
            />
          ))}
        </FilterList>
      </CardContent>
    </Card>
  )
}

export default Aside
