import Attribute, { AttributeOption, AttributeType } from "src/models/Attribute"
import { BooleanInput, TextInput, SelectArrayInput, SelectInput, required } from "react-admin"
import InputAdornment from "@mui/material/InputAdornment"

const PoiAttributeValueInput = ({ attribute }: { attribute: Attribute }) => {
  const inputProps = {
    name: "value",
    source: "value",
    label: attribute.name,
    helperText: attribute.helper_text,
    fullWidth: true,
    validate: [required()],
  }

  if (attribute.unit) {
    inputProps["InputProps"] = {
      endAdornment: <InputAdornment position="end">{attribute.unit}</InputAdornment>,
    }
  }

  if (attribute.options.length > 0) {
    const selectInputProps = {
      choices: attribute.options,
      optionValue: "value",
      optionText: (option: AttributeOption) => option.label || option.value,
    }

    if (attribute.multiple_choice) {
      return <SelectArrayInput {...inputProps} {...selectInputProps} />
    } else {
      return <SelectInput {...inputProps} {...selectInputProps} />
    }
  }

  switch (attribute.type) {
    case AttributeType.INT:
    case AttributeType.FLOAT:
      return <TextInput {...inputProps} style={{ maxWidth: 360 }} inputMode="numeric" type={"number"} />
    case AttributeType.BOOL:
      return <BooleanInput {...inputProps} />
    case AttributeType.EMAIL:
      return <TextInput {...inputProps} type="email" />
    case AttributeType.URL:
      return <TextInput {...inputProps} type="url" />
    case AttributeType.TEXT:
      return <TextInput {...inputProps} multiline />
    case AttributeType.ARRAY:
      return <SelectArrayInput {...inputProps} />
    case AttributeType.STRING:
    default:
      return <TextInput {...inputProps} />
  }
}

const stringToBoolean = (stringValue: string) => {
  switch (stringValue?.toLowerCase()?.trim()) {
    case "true":
    case "yes":
    case "1":
      return true

    case "false":
    case "no":
    case "0":
    case null:
    case undefined:
      return false

    default:
      return JSON.parse(stringValue)
  }
}

export default PoiAttributeValueInput
