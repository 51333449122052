import React from "react"
import { AutocompleteInput, ReferenceInput, required } from "react-admin"

const PoiTypeAutocompleteInput = () => {
  return (
    <ReferenceInput label="Poi type" source="type_id" reference="poi-types">
      <AutocompleteInput optionText="name" optionValue="id" validate={[required()]} suggestionLimit={10} filterToQuery={(searchText) => ({ name: searchText })} />
    </ReferenceInput>
  )
}

export default PoiTypeAutocompleteInput
