import { Edit, ReferenceArrayInput, SimpleForm, TextInput, required, AutocompleteArrayInput } from "react-admin"
import PoiTypeAutocompleteInput from "src/resources/poi-types/PoiTypeAutocompleteInput"
import PoiPublishStateInput from "src/resources/pois/PoiPublishStateInput"

const PoiEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput source="name" validate={[required()]} fullWidth />
        <PoiTypeAutocompleteInput />
        <TextInput source="location.lat" label={"Latitude"} />
        <TextInput source="location.lng" label={"Longitude"} />
        <PoiPublishStateInput />
        <ReferenceArrayInput source="category_ids" reference="categories">
          <AutocompleteArrayInput label="Categories" fullWidth />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  )
}

export default PoiEdit
