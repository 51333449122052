import { LocationOn } from "@mui/icons-material"

import PoiList from "src/resources/pois/PoiList"
import PoiCreate from "src/resources/pois/PoiCreate"
import PoiShow from "src/resources/pois/PoiShow"
import PoiEdit from "src/resources/pois/PoiEdit"
import Poi from "../../models/Poi"

const resource = {
  show: PoiShow,
  list: PoiList,
  create: PoiCreate,
  edit: PoiEdit,
  icon: LocationOn,
  recordRepresentation: (record: Poi) => `${record.name}`,
}

export default resource
