import { GroupAdd } from "@mui/icons-material"

import UploaderList from "src/resources/uploaders/UploaderList"
import UploaderShow from "src/resources/uploaders/UploaderShow"

const resource = {
  list: UploaderList,
  show: UploaderShow,
  icon: GroupAdd,
}

export default resource
