import * as React from "react"
import { useState } from "react"
import Box from "@mui/material/Box"

import { DashboardMenuItem, MenuItemLink, MenuProps, useSidebarState } from "react-admin"

import SubMenu from "src/common/SubMenu"

import pois from "src/resources/pois"
import poimedia from "src/resources/poi-media"
import attributes from "src/resources/attributes"
import poitypes from "src/resources/poi-types"
import categories from "src/resources/categories"
import users from "src/resources/users"
import uploaders from "src/resources/uploaders"
import areas from "src/resources/areas"
import areatypes from "src/resources/area-types"

type MenuName = "categorisation" | "areas" | "users"

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    areas: true,
    categorisation: true,
    users: true,
  })
  const [open] = useSidebarState()

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }))
  }

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <DashboardMenuItem to="/" />
      <MenuItemLink to="/pois" state={{ _scrollToTop: true }} primaryText={"Pois"} leftIcon={<pois.icon />} dense={dense} />
      <MenuItemLink to="/poi-media" state={{ _scrollToTop: true }} primaryText={"Poi media"} leftIcon={<poimedia.icon />} dense={dense} />
      <SubMenu handleToggle={() => handleToggle("categorisation")} isOpen={state.categorisation} name="Categorisation" icon={<poitypes.icon />} dense={dense}>
        <MenuItemLink to="/categories" state={{ _scrollToTop: true }} primaryText={"Categories"} leftIcon={<categories.icon />} dense={dense} />
        <MenuItemLink to="/poi-types" state={{ _scrollToTop: true }} primaryText={"Poi types"} leftIcon={<poitypes.icon />} dense={dense} />
        <MenuItemLink to="/attributes" state={{ _scrollToTop: true }} primaryText={"Attributes"} leftIcon={<attributes.icon />} dense={dense} />
      </SubMenu>
      <SubMenu handleToggle={() => handleToggle("areas")} isOpen={state.areas} name="Areas" icon={<areas.icon />} dense={dense}>
        <MenuItemLink to="/areas" state={{ _scrollToTop: true }} primaryText={"Areas"} leftIcon={<areas.icon />} dense={dense} />
        <MenuItemLink to="/area-types" state={{ _scrollToTop: true }} primaryText={"Area types"} leftIcon={<areatypes.icon />} dense={dense} />
      </SubMenu>
      <SubMenu handleToggle={() => handleToggle("users")} isOpen={state.users} name="Users" icon={<users.icon />} dense={dense}>
        <MenuItemLink to="/users" state={{ _scrollToTop: true }} primaryText={"Users"} leftIcon={<users.icon />} dense={dense} />
        <MenuItemLink to="/uploaders" state={{ _scrollToTop: true }} primaryText={"Uploaders"} leftIcon={<uploaders.icon />} dense={dense} />
      </SubMenu>
    </Box>
  )
}

export default Menu
