import { CircleOutlined } from "@mui/icons-material"

import AreaList from "src/resources/areas/AreaList"
import AreaShow from "src/resources/areas/AreaShow"
import AreaEdit from "src/resources/areas/AreaEdit"
import Area from "../../models/Area"

const resource = {
  list: AreaList,
  show: AreaShow,
  edit: AreaEdit,
  icon: CircleOutlined,
  recordRepresentation: (record: Area) => `${record.name}`,
}

export default resource
