import { Edit, required, SelectInput, SimpleForm, TextInput, ReferenceInput, ImageInput, ImageField } from "react-admin"
import { RichTextInput } from "ra-input-rich-text"

const AreaEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput source="name" validate={[required()]} fullWidth />
        <ReferenceInput source="type_id" reference="area-types">
          <SelectInput optionText="name" validate={[required()]} />
        </ReferenceInput>

        <RichTextInput source="description" fullWidth />

        <ImageInput source="image" label="Area featured image">
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  )
}

export default AreaEdit
