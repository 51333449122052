import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark"

import CategoryList from "src/resources/categories/CategoryList"
import CategoryCreate from "src/resources/categories/CategoryCreate"
import CategoryShow from "src/resources/categories/CategoryShow"
import CategoryEdit from "src/resources/categories/CategoryEdit"
import Category from "src/models/Category"

const resource = {
  show: CategoryShow,
  list: CategoryList,
  create: CategoryCreate,
  edit: CategoryEdit,
  icon: CollectionsBookmarkIcon,
  recordRepresentation: (record: Category) => record?.name,
}

export default resource
