import { Box, Card, CardContent, CardHeader, Divider, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Stack } from "@mui/material"
import { ChipField, EditButton, FunctionField, Labeled, ReferenceManyField, SimpleShowLayout, SingleFieldList, TextField, WrapperField, useRecordContext, ArrayField } from "react-admin"
import PoiMedia from "src/models/PoiMedia"
import { OpenInNew } from "@mui/icons-material"
import { PublishStateField } from "./PublishStateField"
import PoiTypeIcon from "src/common/PoiTypeIcon"
import Poi from "src/models/Poi"

const PoiSummary = () => {
  const poi = useRecordContext()
  return (
    <Grid sx={{ mt: 0 }} container spacing={2}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title="Poi details" action={<EditButton />} />
          <Divider />
          <SimpleShowLayout>
            <Box sx={{ display: "flex", gap: 6 }}>
              <Stack spacing={3}>
                <Labeled>
                  <TextField label="ID" source="id" />
                </Labeled>
                <Labeled label="Publish state">
                  <PublishStateField />
                </Labeled>
              </Stack>
              <Stack spacing={3}>
                <Labeled>
                  <TextField label="Name" source="name" />
                </Labeled>
                <Labeled>
                  <WrapperField label="Poi type" sortBy="name">
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                      <FunctionField render={(poi: Poi) => <PoiTypeIcon poiType={poi.type} />} />
                      <TextField source="type.name" />
                    </Box>
                  </WrapperField>
                </Labeled>
              </Stack>
              <Stack spacing={3}>
                <Labeled>
                  <ArrayField source="categories">
                    <SingleFieldList linkType="show" resource="categories">
                      <ChipField source="name" size="small" />
                    </SingleFieldList>
                  </ArrayField>
                </Labeled>
              </Stack>
            </Box>

            {poi.slug && (
              <Stack>
                <Labeled>
                  <TextField label="Slug" source="slug" />
                </Labeled>
                <Labeled>
                  <FunctionField label="URL" render={() => <>{<a href={`https://kamzavikend.si/ideja/${poi.slug}`}>https://kamzavikend.si/ideja/{poi.slug}</a>}</>} />
                </Labeled>
              </Stack>
            )}
          </SimpleShowLayout>
        </Card>

        {poi.location && (
          <Card sx={{ mt: 3 }}>
            <CardHeader title="Location" />
            <Divider />
            <CardContent>
              <Box display={"flex"} gap={3}>
                <Labeled>
                  <TextField label="Latitude" source="location.lat" />
                </Labeled>
                <Labeled>
                  <TextField label="Longitude" source="location.lng" />
                </Labeled>
              </Box>
              <Labeled>
                <ReferenceManyField label="Areas" reference="areas" target="poi_id">
                  <SingleFieldList>
                    <ChipField source="name" />
                  </SingleFieldList>
                </ReferenceManyField>
              </Labeled>
              <Box mt={2}>
                <Labeled>
                  <ReferenceManyField label="Static map image" reference="poi-media" target="poi_id" filter={{ media_type: "static-map-image" }}>
                    <SingleFieldList linkType={false}>
                      <FunctionField sx={{ pt: 2 }} render={(staticMapImage: PoiMedia) => <img src={staticMapImage.files.original.url} width={300} height={300} alt="" />} />
                    </SingleFieldList>
                  </ReferenceManyField>
                </Labeled>
              </Box>
            </CardContent>
          </Card>
        )}
      </Grid>

      <Grid item xs={12} md={6}>
        {poi.external_url && (
          <Card sx={{ mb: 2 }}>
            <CardHeader title="External sources" />
            <Divider />
            <List dense={true}>
              {poi.external_url && (
                <ListItem>
                  <ListItemText secondary={<span>{poi.external_url}</span>} primary={poi.name} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" href={poi.external_url} target="_blank" color="primary">
                      <OpenInNew />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )}
            </List>
          </Card>
        )}
        <Card>
          <CardHeader title="Analytics" />
          <Divider />
          <CardContent></CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default PoiSummary
