import Poi from "src/models/Poi"
import Uploader from "src/models/Uploader"
import User from "src/models/User";

export interface File {
  height?: number
  url: string
  width?: number
}

export interface ImageConversions {
  thumb?: File
  medium?: File
  large?: File
  facebook?: File
}

interface Files {
  conversions: ImageConversions
  original: File
}

export enum PoiMediaType {
  StaticMap = "static-map-image",
  GalleryPhoto = "gallery-photo",
  FeaturedPhoto = "featured-photo",
  UploadedPhoto = "uploaded-photo",
}

interface ImageLabel {
  id: number
  description: string
  score: number
  topicality: number
}

interface ImageColour {
  red: number
  green: number
  blue: number
  hex: string
  score: number
  pixelFraction: number
}

interface ClassifiedImage {
  labels: ImageLabel[]
  colours: ImageColour[]
}

interface PoiMedia {
  created_at: string
  files: Files
  id: number
  media_type: PoiMediaType
  meta?: object
  description?: string
  author?: string
  order?: number
  poi_id: number
  poi?: Poi
  updated_at: "updated_at"
  uploader_id?: number
  uploader?: Uploader
  is_winter: boolean
  uploaded_by?: string
  user: User
  classifiedImage: ClassifiedImage
}

export default PoiMedia
