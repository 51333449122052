import mapAttribute from "src/models/mapping/mapAttribute"
import mapPoi from "src/models/mapping/mapPoi"
import mapPoiMedia from "src/models/mapping/mapPoiMedia"
import mapPoiAttribute from "src/models/mapping/mapPoiAttribute"
import mapPoiType from "src/models/mapping/mapPoiType"
import mapArea from "src/models/mapping/mapArea"
import mapCategory from "./mapCategory"

export default {
  pois: mapPoi,
  attributes: mapAttribute,
  "poi-media": mapPoiMedia,
  "poi-attributes": mapPoiAttribute,
  "poi-types": mapPoiType,
  areas: mapArea,
  categories: mapCategory,
}
