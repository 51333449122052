import { Table, TableCell, TableRow, TableHead, TableBody } from "@mui/material"
import { useRecordContext } from "ra-core"
import React from "react"
import PoiMedia from "src/models/PoiMedia"

export const ImageLabels = () => {
  const record = useRecordContext<PoiMedia>()
  const labels = record?.classifiedImage?.labels

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Description</TableCell>
          <TableCell>Score</TableCell>
          <TableCell>Topicality</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {labels &&
          labels.map((label) => (
            <TableRow key={label.id}>
              <TableCell component="th" scope="row">
                {label.description}
              </TableCell>
              <TableCell>{label.score}</TableCell>
              <TableCell>{label.topicality}</TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  )
}
