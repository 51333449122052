import React, { useEffect } from "react"
import usePoiIDParam from "src/hooks/usePoiIDParam"
import { Create, ReferenceInput, required, SelectInput, ImageField, ImageInput, Form, SaveButton, useNotify, useRedirect, useDataProvider } from "react-admin"
import { Box, Link, Typography } from "@mui/material"
import { useTheme } from "@mui/system"
import { UploadCloud } from "react-feather"

const PoiMediaCreate = () => {
  const theme = useTheme()
  const poi_id = usePoiIDParam()
  const notify = useNotify()
  const redirect = useRedirect()
  const dataProvider = useDataProvider()

  useEffect(() => {
    if (!poi_id) {
      redirect("list", "poi-media")
    }
  }, [poi_id])

  const onSuccess = () => {
    notify("Photos uploaded!")
    redirect(`/pois/${poi_id}/show/photos`)
  }

  const onError = (error) => {
    notify(`Some photos could not be uploaded: ${error.message}`)
  }

  const savePoiMedia = async ({ poi_id, files }) => {
    try {
      await dataProvider.uploadPoiMedia({ poi_id, files })
      onSuccess()
    } catch (e) {
      onError(e)
    }
  }

  return (
    <Create mutationOptions={{ onSuccess, onError }}>
      <Form defaultValues={{ poi_id }} onSubmit={savePoiMedia}>
        <ReferenceInput source="poi_id" reference="pois" allowEmpty validate={required()}>
          <SelectInput optionText="name" disabled />
        </ReferenceInput>
        <Box>
          <ImageInput
            source="files"
            accept="image/*"
            label={"Photos"}
            multiple
            placeholder={
              <Typography color="textPrimary">
                Drag here or <Link underline="always">select</Link> from device.
              </Typography>
            }
            sx={{
              border: `1px dashed ${theme.palette.divider}`,
              padding: theme.spacing(6),
              outline: "none",
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              alignItems: "center",
              "&:hover": {
                backgroundColor: theme.palette.action.hover,
                opacity: 0.5,
                cursor: "pointer",
              },
            }}
          >
            <ImageField
              source="src"
              title="title"
              sx={{
                width: 200,
                "& img": {
                  maxWidth: "100%",
                  objectFit: "contain",
                },
              }}
            />
          </ImageInput>
        </Box>
        <SaveButton
          label={"Upload all"}
          icon={
            <Box mr={1} display={"flex"} alignItems={"center"}>
              <UploadCloud />
            </Box>
          }
        />
      </Form>
    </Create>
  )
}

export default PoiMediaCreate
