import { Tab, TabbedShowLayout, Show, TopToolbar, EditButton } from "react-admin"
import PoiDescription from "src/resources/pois/PoiDescription"
import PoiSummary from "src/resources/pois/PoiSummary"
import PoiPhotos from "src/resources/pois/PoiPhotos"
import PoiAttributes from "src/resources/pois/PoiAttributes"

const PoiShowActions = () => (
  <TopToolbar>
    <EditButton />
  </TopToolbar>
)

const PoiShow = () => {
  return (
    <Show actions={<PoiShowActions />}>
      <TabbedShowLayout>
        <Tab label="summary">
          <PoiSummary />
        </Tab>
        <Tab label="Description" path={"description"}>
          <PoiDescription />
        </Tab>
        <Tab label="Photos" path={"photos"}>
          <PoiPhotos />
        </Tab>
        <Tab label="Attributes" path={"attributes"}>
          <PoiAttributes />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default PoiShow
