import React from "react"
import { CardContent, Button, Typography, Card, Box } from "@mui/material"

const ExternalDescription = ({ content, url }: { content: string; url: string }) => {
  return (
    <Card title="">
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Typography gutterBottom variant="h6">
            Description from external sources
          </Typography>
          {url && (
            <Button href={url} target="_blank" variant="outlined">
              Open external source
            </Button>
          )}
        </Box>
        <Typography dangerouslySetInnerHTML={{ __html: content }} variant="body2"></Typography>
      </CardContent>
    </Card>
  )
}

export default ExternalDescription
