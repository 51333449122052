import React from "react"
import { Create, required, SimpleForm, TextInput, SelectInput, minLength, email, ReferenceArrayInput, AutocompleteArrayInput } from "react-admin"

const CategoryCreate = () => {
  return (
    <Create redirect="show">
      <SimpleForm>
        <TextInput source="name" validate={[required()]} />
        <ReferenceArrayInput source="attribute_ids" reference="attributes">
          <AutocompleteArrayInput label="Key attributes" fullWidth />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  )
}

export default CategoryCreate
