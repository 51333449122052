import { Edit, required, SimpleForm, TextInput, BooleanInput, ReferenceArrayInput, AutocompleteArrayInput } from "react-admin"

const CategoryEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput source="name" validate={[required()]} fullWidth />
        <BooleanInput source="is_published" />
        <BooleanInput source="is_featured" />
        <ReferenceArrayInput source="attribute_ids" reference="attributes">
          <AutocompleteArrayInput label="Key attributes" fullWidth />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  )
}

export default CategoryEdit
