import { fetchUtils } from "react-admin"

const fetcher = (
  url,
  options: {
    headers?: Headers
    method?: string
    body?: string | FormData
  } = {
    method: "GET",
  }
) => {
  if (!options.headers) options.headers = new Headers()

  options.headers.set("Accept", "application/json")

  const { token } = JSON.parse(localStorage.getItem("auth"))
  options.headers.set("Authorization", `Bearer ${token}`)

  return fetchUtils.fetchJson(url, options)
}

export default fetcher
