import React from "react"
import { Grid } from "@mui/material"
import Poi from "src/models/Poi"
import { ReferenceManyField, useRecordContext, SimpleList } from "react-admin"
import AddNewPoiDescriptionButton from "src/resources/poi-descriptions/AddNewPoiDescriptionButton"
import ExternalDescription from "src/resources/pois/ExternalDescription"

const PoiDescription = () => {
  const poi = useRecordContext<Poi>()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <ReferenceManyField label={false} reference="poi-descriptions" target="poi_id" sort={{ field: "created_at", order: "DESC" }} emptyText={"No descriptions"}>
          <SimpleList
            linkType={(record) => `/poi-descriptions/${record.id}?poi_id=${poi.id}`}
            primaryText={(record) => <div dangerouslySetInnerHTML={{ __html: record.content }} />}
            secondaryText={(record) => record.type}
          />
        </ReferenceManyField>
        <AddNewPoiDescriptionButton poi={poi} />
      </Grid>
      <Grid item xs={12} md={6}>
        {poi.external_description && <ExternalDescription content={poi.external_description} url={poi.external_url} />}
      </Grid>
    </Grid>
  )
}

export default PoiDescription
